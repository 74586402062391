.case-card {
  padding-top: 20px;
}
.case-card-header {
  padding-bottom: 20px;
}

.case-card-descriptions {
  margin-top: 12px;
}
