.upload-passport {
  display: flex;
  flex-direction: column;
}

.upload-passport .upload-passport-uploader {
  margin: 40px 0 0 0;
  align-self: center;
}

.upload-passport .upload-passport-controls {
  margin: 40px 0 0 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
