.upload-item-container {
  position: relative;
  height: 100%;
}

.upload-item-actions {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.upload-item-actions button {
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  top: calc(50% - 20px);
  left: calc(50% - 30px);
}

.upload-item-actions:hover {
  opacity: 1;
}

.uploader.ant-upload-wrapper.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: fit-content;
}

.uploader .ant-upload.ant-upload-select {
  margin: 0 !important; /** Remove ant design margin, don't use !important unless necessary */
}

.uploader-upload {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.uploader-upload > svg,
.uploader-upload > span {
  width: 14px;
  height: 14px;
}

.uploader-disabled {
  width: 100px;
  height: 100px;
  background-color: var(--upload-btn-background);
  border: var(--upload-btn-border);
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploader-disabled svg {
  fill: var(--gray-color-2);
}

.miltifile-uploader-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
