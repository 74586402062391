.home-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 72px);
  position: sticky;
  top: 0;
}
/* .home-heading {

}
.home-heading-ai {
  color: transparent;
  background: linear-gradient(to bottom, #27AE60 50%, #D6F0E1 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */
.top-section {
  position: relative;
  z-index: 1;
  background-image: linear-gradient(180deg, #eefff6 0%, #ffffff 22.5%, #ffffff 72.5%, #eefff7 99.99%);
}

/* .first-home-button {
  z-index: 1;
  color: var(--white-color) !important;
  background: var(--primary-color) !important;
  width: 160px;
  height: 40px;
  margin: 0 !important;
}
.first-home-button:hover {
  color: var(--primary-color) !important;
  background: var(--primary-color-background) !important;
} */

.first-home-button {
  z-index: 1;
  color: var(--white-color) !important;
  background: linear-gradient(135deg, #04befe, #27ae60);
  border: none;
  width: 160px;
  height: 40px;
  margin: 0 !important;
  position: relative;
  overflow: hidden;
}

.first-home-button::before {
  content: "";
  background: linear-gradient(135deg, #27ae60, #04befe);
  position: absolute;
  inset: 0;
  opacity: 1;
  transition: opacity 1s;
  border-radius: inherit;
  z-index: -1;
}

.first-home-button:hover::before {
  opacity: 0;
}

.first-home-button:hover {
  color: var(--white-color) !important;
  background: linear-gradient(135deg, #0468fe, #04befe) !important;
}

.home-section.center.first {
  padding: 120px 0 80px;
}
.xsmall .home-section.center.first,
.small .home-section.center.first {
  padding: 80px 0 60px;
}

.home-section {
  display: flex;
}

.home-section.center {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-image {
  height: auto;
}
.background-image {
  position: relative;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -10%;
}

.border-bottom-2px {
  border-bottom: 2px solid var(--black-color);
}

.landing-page-title {
  width: 665px;
  margin: 20px 0 40px;
}

.xsmall .landing-page-title,
.small .landing-page-title {
  width: 70%;
}
.large .landing-page-title {
  width: 40%;
}

.cn.large .landing-page-title {
  width: 30%;
}

.buildings {
  width: 400px;
  margin-bottom: -7px;
  max-width: 600px;
}

.liberty {
  width: 550px;
  margin-bottom: -8px;
  max-width: 800px;
}

.home-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 10px 0 40px 0;
  text-align: center;
}

.xsmall .home-text,
.small .home-text {
  width: 60%;
}

.en .fast-track-description {
  color: var(--gray-color-1);
}

.cn .fast-track-description {
  color: var(--gray-color-2);
}

.home-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 0;
}
.small .home-video,
.xsmall .home-video {
  padding: 20px 0;
}
.home-video-player {
  width: 80vw;
  height: calc(80vw * (675 / 1200));
  max-width: 1200px;
  max-height: 675px;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.small .home-video-player,
.xsmall .home-video-player {
  width: 90vw;
  height: calc(90vw * (675 / 1200));
}

.home-video-player iframe {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
