.error-message {
  color: var(--danger-color);
  font-size: var(--font-size-p3);
  line-height: var(--line-height-p3);
  margin-top: 5px;
}

.text.primary {
  color: var(--primary-color);
}

.text.secondary {
  color: var(--gray-color-6);
}

.text.gray {
  color: var(--gray-color-7);
}

.text.dark {
  color: var(--gray-color-1);
}

.text.warning {
  color: var(--warning-color);
}

.text.danger {
  color: var(--danger-color);
}

.text.xlarge {
  font-weight: 600;
  font-size: var(--font-size-h2-small);
}

.xsmall .text.xlarge,
.small .text.xlarge {
  font-size: var(--font-size-h3);
}

.text.large {
  font-weight: 600;
  font-size: var(--font-size-h3);
}

.text.medium {
  font-size: var(--font-size-p1);
}

.text.normal {
  font-size: var(--font-size-p2);
}

.text.small {
  font-size: var(--font-size-p2);
}

.text.xsmall {
  font-size: var(--font-size-p3);
}

.text.upload {
  font-size: var(--font-size-upload);
  line-height: 18px;
}

.text.normal.bold {
  font-size: var(--font-size-p2);
  font-weight: 600;
}

.text.margin-bottom-20 {
  margin-bottom: 20px;
}

.text.margin-bottom-10 {
  margin-bottom: 10px;
}

.text.margin-bottom-0 {
  margin-bottom: 0;
}

.text.margin-5 {
  margin: 5px;
}

.single-line-text {
  display: flex;
  gap: 10px;
}

.text.field-label {
  font-size: var(--font-size-p2);
  font-weight: 600;
  margin-bottom: 5px;
}

.text.placeholder {
  color: var(--gray-color-7);
  font-size: var(--inline-placeholder);
}

.text.no-wrap {
  text-wrap: nowrap;
}

.qtag {
  background-color: var(--gray-color-4);
  border: 1px solid var(--gray-color-3);
  border-radius: 5px;
  padding: 2px 8px;
  width: fit-content;
  font-size: var(--font-size-p3);
}
