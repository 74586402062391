.delete-confirm-modal-container {
  margin: auto;
  padding: 32px;
}
.delete-confirm-modal-body {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  padding: 15px 10px;
  gap: 10px;
}
.delete-confirm-modal-icon {
  padding-top: 1px;
}
.delete-confirm-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
