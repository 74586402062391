.text-block {
  padding: 80px 40px;
  max-width: 800px;
  box-sizing: border-box;
}

.xsmall .text-block,
.small .text-block {
  padding: 20px;
  width: 75%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.text-block-title {
  font-size: var(--font-size-h2);
  font-weight: var(--font-weight-bold);
  line-height: 56px;
  padding-bottom: 20px;
}

.xsmall .text-block-title,
.small .text-block-title {
  font-size: var(--font-size-h3);
  line-height: 40px;
}

.text-block-title.h3 {
  font-size: var(--font-size-h3);
  color: var(--primary-color);
  line-height: 40px;
}

.xsmall .text-block-title.h3,
.small .text-block-title.h3 {
  font-size: var(--font-size-h4);
  line-height: 36px;
}

.text-block-description {
  font-size: 20px;
  line-height: 28px;
  color: var(--gray-color-2);
}
