:root {
  --primary-color: #27ae60;
  --primary-color-trans1: rgba(39, 174, 96, 0.2);
  --primary-color-light: rgba(39, 174, 96, 0.1);
  --primary-color-light-notrans: #ebf7ef;
  --primary-color-background: #eefff7;
  --secondary-color: #6c757d;
  --dark-gray-color: #3e4c6f;
  --link-color: #2b8852;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #f2994a;
  --info-color: #4e745e;
  --info-color-background: #f6f9f4;
  --line-color: #231f20;
  --white-color: #ffffff;
  --white-color-transparent-1: rgba(255, 255, 255, 0.2);
  --white-color-transparent-2: rgba(255, 255, 255, 0.4);
  --black-color: #000000;
  --gray-color-1: #333333;
  --gray-color-2: #828282;
  --gray-color-3: #e0e0e0;
  --gray-color-4: #f2f2f2;
  --gray-color-5: #4f4f4f;
  --gray-color-6: #bdbdbd;
  --gray-color-7: #9e9e9e;
  --gray-color-semi-transparent: rgba(0, 0, 0, 0.6);
  --body-bg: #f8f9fa;
  --body-color: #212529;
  --border-radius: 0.25rem;
  --transition: all 0.5s ease-in-out;
  --font-weight-bold: 600;
  --font-size-h2: 40px;
  --font-size-h2-small: 30px;
  --font-size-h3: 24px;
  --font-size-h4: 20px;
  --font-size-p1: 20px;
  --font-size-p2: 16px;
  --font-size-p3: 14px;
  --font-size-upload: 13px;
  --font-color-collapse-level1: rgba(0, 0, 0, 0.88);
  --inline-placeholder: 11px;
  --line-height-p3: 16px;
  --textbox-width: 250px;
  --upload-btn-background: rgba(0, 0, 0, 0.02);
  --upload-btn-border: 1px dashed #d9d9d9;
  --upload-btn-hover: #4096ff;
  --shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --shadow-green: 0 6px 16px 0 rgba(39, 174, 96, 0.2), 0 3px 6px -4px rgba(39, 174, 96, 0.12),
    0 9px 28px 8px rgba(39, 174, 96, 0.05);
  --border: 1px solid #f2f2f2;
}

.single-line {
  display: flex;
  gap: 10px;
  align-items: center;
}

.default-button {
  border: 1px solid var(--gray-color-3);
}

.horizontal-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.horizontal-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.horizontal-2 > .sub-field {
  flex: 0 1 auto;
  max-width: calc(50% - 20px);
}

.horizontal-3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  max-width: calc(var(--textbox-width) * 3 + 60px);
  width: 100%;
}

.horizontal-3 > .sub-field {
  flex: 0 1 auto;
  min-width: calc(33.33% - 20px);
}

.xsmall .horizontal-2 > .sub-field,
.small .horizontal-2 > .sub-field,
.xsmall .horizontal-3 > .sub-field,
.small .horizontal-3 > .sub-field {
  flex: 1 1 100%;
}

.horizontal-3 > .form-field-single-line {
  min-width: 100%;
}

.ant-upload img {
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
}
