.home-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(180deg, #ffffff 0%, #ecfdf3 100%);
  padding: 20px 160px;
  width: 100%;
}

.section-title-button {
  margin: 75px 0 15px 0;
  padding: 3px 15px;
  border-radius: 25px;
  background-color: var(--primary-color-trans1);
  color: black;
}

.hs-text-block {
  padding: 0 20px 20px 20px !important;
}
.xsmall .hs-text-block,
.small .hs-text-block {
  width: 75%;
}
.text-block-title.h3 {
  color: black !important;
  padding-bottom: 0 !important;
  line-height: 35px;
}

.hs-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 1200px;
  padding: 10px 0 40px;
  gap: 40px;
}
.xsmall .hs-section,
.small .hs-section {
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
}
.xsmall .hs-section > div .small .hs-section > div {
  width: 20%;
}
.small .hs-section > svg,
.xsmall .hs-section > svg {
  width: 90vw;
}
.hs-subsection {
  display: flex;
  flex-direction: column;
  min-width: 550px;
  padding-left: 120px;
  padding-top: 60px;
}

.hs-subsection-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 120px;
}
.small .hs-subsection-item,
.xsmall .hs-subsection-item {
  gap: 0;
}
.hs-subsection-item svg {
  min-width: 50px;
  padding-bottom: 30px;
}
.xsmall .hs-subsection-item svg,
.small .hs-subsection-item svg {
  padding-left: 40px;
}

.hs-text-block.text-block-description {
  font-size: 16px;
  padding-left: 0 !important;
}
