.caseStatus {
  overflow-y: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
}
