.form-control-container-missing {
  position: relative;
  border-left: 3px solid var(--warning-color);
}
.form-control-container {
  position: relative;
  border: none;
}
.section-popup .form-control-container {
  margin-bottom: 40px;
}
