.contact-us-icon {
  margin-top: 100px;
}

.contact-us-icon svg {
  display: block;
  margin: 0 auto;
  max-width: 1200px;
}

.contact-us-icon.quickimmi {
  max-width: 400px;
  width: 50%;
  margin: 0 auto;
  margin-top: 60px;
}

.contact-us-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  width: 100%;
}

/* .xsmall .contact-us-content,
.small .contact-us-content,
.medium .contact-us-content {
  align-items: center;
} */

.contact-us-content > div {
  padding: 0 80px;
  text-align: center;
}

/* .contact-us-content > .phone {
  border-right: 1px solid var(--gray-color-3);
  border-left: 1px solid var(--gray-color-3);
} */

.xsmall .contact-us-content > .phone,
.small .contact-us-content > .phone,
.medium .contact-us-content > .phone {
  border: none;
}
