.document-list a {
  display: block;
}

.document-list-action {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.document-list-inner {
  width: 100%;
}

.document-list-btn {
  float: right;
  margin: 10px;
}

.document-list-inner th {
  background: none !important;
}
