.ps-text-area-container {
  height: 1000px;
  display: flex;
  flex-direction: row;
}

.ps-text-area-before {
  width: 100%;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
  padding: 5px 10px;
}

.ps-text-area-after {
  height: inherit;
  width: 50%;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
  padding: 5px 10px;
}

.ps-text-area-refined-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ps-text-area-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  background-color: var(--primary-color-light);
  border-radius: 5px;

  margin: 5px;
  padding: 4px 16px 4px 10px;
  cursor: pointer;
}

.ps-text-area-button-discard {
  font-size: 28px;
  color: var(--gray-color-2);
  border-radius: 5px;
  margin-left: auto;
  transition: color 0.3s ease-in-out;
}

.ps-text-area-button-discard:hover {
  color: red;
}

.ps-text-area-input {
  height: 950px !important;
  resize: none !important;
  padding: 5px !important;
}
