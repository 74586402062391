.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color-light);
}

.section-header-control {
  display: flex;
  gap: 1rem;
}
