.form-flow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--gray-color-4);
  height: 100%;
  padding: 0px 40px 0 40px;
  box-sizing: border-box;
}

.xsmall .form-flow,
.small .form-flow {
  padding: 10px 0;
}

.form-top {
  margin: 0 auto;
  max-width: 1460px;
  width: 100%;
}

.form-flow-icon {
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.form-flow-icon svg {
  display: block;
}

.xsmall .form-flow-icon svg,
.small .form-flow-icon svg {
  width: 80px;
  height: auto;
}

.form-flow-content {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  gap: 10px;
  overflow-y: hidden;
  height: calc(100vh - 270px); /* Adjust according to your layout */
}
