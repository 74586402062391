.ps-text-area-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 600px;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
}

.ps-select-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 150px;
  gap: 50px;
}
.ps-select-card {
  width: 250px;
  background: #eefff6;
}

.ps-select-card-button {
  margin: 20px 10px 0 80px;
  color: var(--primary-color);
}
.ps-select-card-button:hover {
  color: var(--link-color) !important;
}

.ps-text-area-before {
  width: inherit;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
  padding: 5px 10px;
}

.ps-text-area {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--gray-color-3);
  height: 500px;
}

.ps-text-area-after {
  height: inherit;
  width: 50%;
  padding: 5px 10px;
  border: none;
}

.ps-text-area-subtitle {
  display: flex;
  flex-direction: column;
}

.ps-text-area-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  background-color: var(--primary-color-light);
  border-radius: 5px;

  margin: 5px;
  padding: 4px 16px 4px 10px;
  cursor: pointer;
}

.ps-text-area-button-discard {
  font-size: 28px;
  color: var(--gray-color-2);
  border-radius: 5px;
  margin-left: auto;
  transition: color 0.3s ease-in-out;
}

.ps-text-area-button-discard:hover {
  color: red;
}

.ps-text-area-input {
  height: inherit !important;
  resize: none !important;
  padding: 2px !important;
}
.ps-user-own-input {
  height: 523px !important;
  resize: none !important;
  padding: 5px 10px;
  border: 1px solid var(--gray-color-3);
  border-left: none;
  border-right: none;
}
.ps-user-own-button {
  margin: 5px 0 10px 85%;
  color: var(--primary-color);
}
.ps-user-own-button:hover {
  color: var(--link-color) !important;
}

.text-area-prompt-input-container > .suffix-icon {
  display: flex;
  position: absolute;
  cursor: pointer;
  align-items: center;
  padding: 10px;
  z-index: 2;
  right: 0;
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub {
  z-index: 999;
  position: absolute;
  padding: 8px 4px;
  margin-top: 1px;
  border-radius: 10px;
  background-color: var(--white-color) !important;
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3);
}
