.entry-records-btn {
  color: var(--gray-color-2);
}

.entry-record-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
