.privacy > h1 {
  margin-bottom: 10px;
}

.privacy-collapse {
  margin: 20px -20px;
}

.privacy-outlined svg {
  font-size: 20px;
}

.privacy-outlined.active svg {
  fill: var(--primary-color);
}

.privacy-policy-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.privacy td,
.privacy th {
  border: 1px solid var(--gray-color-3);
  padding: 8px;
}

.privacy-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--dark-gray-color);
}
