.upload-item-container {
  position: relative;
  height: 100%;
}

.upload-item-actions {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.upload-item-actions button {
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  top: calc(50% - 20px);
  left: calc(50% - 30px);
}

.upload-item-actions:hover {
  opacity: 1;
}
