.text-area-container {
  display: flex;
  flex-direction: row;
}

.text-area-before {
  width: 100%;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
  padding: 5px 10px;
}

.text-area-after {
  width: 100%;
  border: 1px solid var(--gray-color-3);
  border-radius: 3px;
  padding: 5px 10px;
}

.text-area-logo-gray {
  color: "#9e9e9e";
}
.text-area-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  background-color: var(--primary-color-light);
  border-radius: 5px;

  margin: 5px;
  height: 35px;
  padding: 4px 16px 4px 10px;
  cursor: pointer;
}

.text-area-switch-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.text-area-switch {
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  color: var(--gray-color-3);
}
.text-area-switch.selected {
  color: var(--primary-color);
}
.text-area-switch.border {
  border-left: 2px solid var(--gray-color-6);
  border-radius: 0;
}

.text-area-btn-discard {
  font-size: 20px;
  color: var(--gray-color-7);
  border-radius: 5px;
  margin-left: auto;
  margin-right: 10px;
  transition: color 0.3s ease-in-out;
}
.text-area-btn-discard:hover {
  color: var(--danger-color);
}

.text-area-input {
  height: 360px !important;
  resize: none !important;
  padding: 5px !important;
}

.text-area-AiMenu {
  border: none;
  border-inline-end: none !important;
}

.text-area-prompt-input-container {
  display: flex;
  flex-direction: row;
}
.text-area-prompt-input-container > .prefix-icon {
  display: flex;
  position: absolute;
  align-items: center;
  padding: 6px;
  z-index: 2;
  left: 0;
}
.text-area-prompt-input-container > .suffix-icon {
  display: flex;
  position: absolute;
  align-items: center;
  padding: 10px;
  z-index: 2;
  right: 0;
}
.text-area-prompt-input {
  display: flex;
  align-items: center;
  padding: 6px 28px;
  font-size: 14px;
  border-radius: 8px;
  max-width: 250px !important;
  border: 1px solid var(--gray-color-3);
  border-radius: 8px;
  resize: none !important;
}

.text-area-prompt-input > .ant-input-suffix > span {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-submenu-title {
  width: 70px;
  height: 35px;
  border-radius: 5px;
  padding-left: 12px !important;
  color: var(--primary-color);
  background: var(--primary-color-light);
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-submenu-title:hover {
  color: var(--white-color);
  background: var(--primary-color);
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub {
  z-index: 999;
  position: absolute;
  padding: 8px 4px;
  border: 2px 0 solid white;
  border-radius: 10px;
  background-color: var(--white-color) !important;
  box-shadow: 0px 18px 36px -18px rgba(0, 0, 0, 0.3);
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-only-child {
  background-color: white !important;
  padding: 2px !important;
  height: auto;
  min-width: 250px;
  margin: 0;
}
.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > .ant-menu-item-only-child:hover {
  background-color: white !important;
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > li > .ant-menu-item-group-title {
  padding-inline-start: 10px;
  padding: 8px 8px 0;
}

.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > li > ul > li {
  padding-left: 12px !important;
  border-radius: 8px;
  font-weight: 500;
}
.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > li > ul > .ant-menu-item-active {
  background-color: var(--primary-color-light) !important;
}
.text-area-AiMenu > .ant-menu-submenu > .ant-menu-sub > li > ul > li > svg {
  padding-right: 12px;
}

.text-area-refined {
  display: flex;
  flex-direction: row;
}

.text-area-tips {
  width: 275px;
  height: 375px;
  margin-top: -40px;
  box-shadow: 0px 6px 12px -2px rgba(50, 50, 93, 0.25);
}
.text-area-tips.hidden {
  display: none;
}
.text-area-tips > .ant-card-head {
  min-height: 40px;
  padding: 0 12px;
}
.text-area-tips > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 5px;
  color: var(--primary-color);
  align-items: center;
}
.text-area-tips > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  font-size: 12px;
  color: var(--gray-color-2);
  cursor: pointer;
}

.text-area-tips > .ant-card-body {
  overflow-y: auto;
  height: 335px;
  padding: 16px 8px !important;
  color: var(--gray-color-2);
}
