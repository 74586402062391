.single-page-view {
  background-color: var(--gray-color-4);
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.single-page-content {
  flex-grow: 1;
  margin: 20px 40px 40px;
  padding: 60px;
  background-color: var(--white-color);
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh;
}

.xsmall .single-page-content,
.small .single-page-content {
  padding: 40px;
  margin: 20px;
}
