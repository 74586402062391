.home-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--gray-color-4);
}

.section-title-button {
  margin: 75px 0 15px 0;
  padding: 3px 15px;
  border-radius: 25px;
  background-color: var(--primary-color-trans1);
  color: black;
}
.hf-text-block-title {
  width: 600px;
  padding: 0 40px;
}
.xsmall .hf-text-block-title,
.small .hf-text-block-title {
  width: 80vw;
  max-width: 360px;
  padding: 20px;
}
.hf-text-block {
  padding: 0 0 20px 40px;
  width: 450px;
}
.hf-text-block.text-block-description {
  min-height: 60px;
}
.text-block-title.h3 {
  color: black !important;
}
.xsmall .hf-text-block,
.small .hf-text-block {
  padding: 0 0 20px 20px;
  width: 80vw;
}

.hf-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  width: 1150px;
  gap: 40px;
}
.xsmall .hf-section,
.small .hf-section {
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}
.xsmall .hf-section svg,
.small .hf-section svg {
  width: 50px;
  align-self: center;
  padding-bottom: 80px;
}

.hf-subsection {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 540px;
  gap: 20px;
}
.xsmall.small .hf-subsection {
  gap: 0;
}
.hf-subsection-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.hf-subsection-item svg {
  min-width: 50px;
  align-self: flex-start;
}

.hf-text-block.text-block-description {
  font-size: 16px;
  padding-left: 0 !important;
}
.xsmall .hf-text-block.text-block-description,
.small .hf-text-block.text-block-description {
  font-size: 14px;
  width: 100%;
}
