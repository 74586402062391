.location-dropdown-container {
  max-width: calc(var(--textbox-width) * 3 + 60px) !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.location-dropdown-container .ant-select {
  width: 100%;
  flex: 0 1 auto;
  max-width: 250px;
}

.location-dropdown-container .ant-select .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid #d9d9d9 !important;
  height: 40px !important;
  background-color: #ffffff !important;
  font-family: "Roboto", sans-serif;
}
